<template>
  <div
    class="flex flex-col items-center bg-[#003d31] text-sm text-white text-nowrap"
  >
    <div
      class="flex max-md:flex-col max-w-[1200px] w-full py-5 max-md:gap-5 max-[1210px]:px-5"
    >
      <div class="w-3/4">
        <h6 class="uppercase">Contact</h6>
        <ul class="columns-1 sm:columns-2 lg:columns-3 mt-3 space-y-1">
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-solid fa-headset" />
            +976 88889878
          </li>
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-solid fa-headset" />
            +976 88116333
          </li>
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-solid fa-headset" />
            +976 89853868
          </li>
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-regular fa-envelope" />
            miningweek@miningmongolia.mn
          </li>
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-regular fa-envelope" />
            ulzii@mmhi.gov.mn
          </li>
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-regular fa-envelope" />
            khulan@mmhi.gov.mn
          </li>
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-solid fa-globe" />
            www.miningmongolia.mn
          </li>
          <li class="flex items-center gap-1">
            <font-awesome-icon icon="fa-solid fa-globe" />
            www.mmhi.gov.mn
          </li>
        </ul>
      </div>

      <div class="">
        <h6 class="uppercase">Social media</h6>
        <div class="flex items-center gap-2 mt-3">
          <a
            href="https://facebook.com"
            target="_blank"
            class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#00a375]"
          >
            <font-awesome-icon icon="fa-brands fa-facebook-f" />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#00a375]"
          >
            <font-awesome-icon icon="fa-brands fa-instagram" />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#00a375]"
          >
            <font-awesome-icon icon="fa-brands fa-x-twitter" />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#00a375]"
          >
            <font-awesome-icon icon="fa-brands fa-linkedin-in" />
          </a>
        </div>
      </div>
    </div>

    <div
      class="max-w-[1200px] w-full border-t border-[#00a375] text-center py-5"
    >
      Copyright by &copy; BOW LLC - 2024
    </div>
  </div>
</template>
