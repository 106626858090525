<template>
  <header
    class="flex justify-center items-center bg-[#003d31] sticky top-0 z-10"
  >
    <div
      class="flex justify-between items-center max-w-[1200px] w-full border-b-2 border-white text-sm px-5"
    >
      <img
        src="../../assets/images/miningWeek/mining-week-logo.png"
        alt="logo"
        class="w-[8rem] h-[2rem] object-fit my-3"
      />
      <button
        @click="toggleDrawer()"
        class="block lg:hidden border border-white rounded px-2 py-1"
      >
        <font-awesome-icon icon="fa-solid fa-bars" class="text-xl text-white" />
      </button>

      <nav class="hidden lg:block">
        <ul class="flex text-white gap-1">
          <li
            v-for="(link, index) in navbar"
            :key="index"
            class="flex justify-center items-center w-[7.5rem] h-[3.5rem] border-b-4 hover:border-[#00a375]"
            :class="
              link.title === 'Home' || link.title === 'Нүүр'
                ? 'border-[#00a375]'
                : 'border-transparent'
            "
          >
            {{ lang === "mn" ? link.title : link.title_en }}
          </li>
          <li class="flex justify-center items-center gap-1 w-[7.5rem]">
            <button
              @click="toggleLanguage('en')"
              class="flex justify-center items-center h-[3.5rem] border-b-4 border-transparent"
              :class="
                lang === 'en' ? 'text-[#00a375]' : 'hover:border-[#00a375]'
              "
            >
              EN
            </button>
            /
            <button
              @click="toggleLanguage('mn')"
              class="flex justify-center items-center h-[3.5rem] border-b-4 border-transparent"
              :class="
                lang === 'mn' ? 'text-[#00a375]' : 'hover:border-[#00a375]'
              "
            >
              MN
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <Transition name="fade" class="min-[1200px]:hidden">
    <div
      v-if="isDrawerOpen"
      class="fixed top-0 left-0 z-20 h-screen w-[18rem] bg-[#003d31] text-white text-sm p-5"
    >
      <button @click="toggleDrawer()" class="absolute top-3 right-3">
        <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
      </button>

      <ul class="mt-10">
        <li
          v-for="(item, index) in navbar"
          :key="index"
          class="border-b border-slate-200 py-3"
        >
          {{ lang === "mn" ? item.title : item.title_en }}
        </li>
        <li class="flex gap-2 border-b border-slate-200 py-3">
          <button
            @click="toggleLanguage('en')"
            :class="lang === 'en' ? 'text-[#00a375]' : 'hover:border-[#00a375]'"
          >
            EN
          </button>
          /
          <button
            @click="toggleLanguage('mn')"
            :class="lang === 'mn' ? 'text-[#00a375]' : 'hover:border-[#00a375]'"
          >
            MN
          </button>
        </li>
      </ul>
    </div>
  </Transition>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWNavbar",
  setup() {
    const store = useStore();
    const lang = ref("");

    watchEffect(() => {
      lang.value = store.getters.getLanguage;
    });

    const toggleLanguage = (value) => {
      store.dispatch("toggleLanguage", value);
    };

    const navbar = [
      {
        title_en: "Home",
        title: "Нүүр",
      },
      {
        title_en: "Agenda",
        title: "Хөтөлбөр",
      },
      {
        title_en: "About",
        title: "Бидний тухай",
      },
      {
        title_en: "Presentation",
        title: "Илтгэл",
      },
      {
        title_en: "Contact",
        title: "Холбоо барих",
      },
    ];

    const isDrawerOpen = ref(false);
    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    return { navbar, lang, toggleLanguage, isDrawerOpen, toggleDrawer };
  },
};
</script>
