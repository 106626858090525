import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./pages/HomePage.vue";

// Mining Mongolia ********************************
import MMHomePage from "./pages/MiningMongolia/MMHomePage.vue";

// Mining Week ********************************
import MWHomePage from "./pages/MiningWeek/MWHomePage.vue";

// const miningMongoliaRoutes = [
//   {
//     path: "/page1",
//     name: "MMHomePage",
//     component: MMHomePage,
//   },
// ];

// const miningWeekRoutes = [
//   {
//     path: "/page2",
//     name: "MWHomePage",
//     component: MWHomePage,
//   },
// ];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: HomePage,
    },
    {
      path: "/page1",
      name: "MMHomePage",
      component: MMHomePage,
    },
    {
      path: "/page2",
      name: "MWHomePage",
      component: MWHomePage,
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/page1") {
    document.title = "Монголын Уул Уурхайн Үндэсний Ассоциаци";
  } else if (to.path === "/page2") {
    document.title = "Mining Week";
  }
  next();
});

export default router;
