<template>
  <div class="flex justify-center items-center w-full h-screen bg-blue-100">
    <div class="flex max-lg:flex-col items-center gap-10">
      <RouterLink to="/page1">
        <div
          class="flex justify-center items-center w-[15rem] sm:w-[25rem] h-[8rem] p-3 bg-white rounded-xl hover:scale-105"
        >
          <img
            src="../assets/images/miningMongolia/header_logo.png"
            alt="logo"
            class="object-fit"
          />
        </div>
      </RouterLink>
      <RouterLink to="/page2">
        <div
          class="flex justify-center items-center w-[15rem] sm:w-[25rem] h-[8rem] p-3 bg-[#003d31] rounded-xl hover:scale-105"
        >
          <img
            src="../assets/images/miningWeek/mining-week-logo.png"
            alt="logo"
            class="object-fit"
          />
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  setup() {
    return {};
  },
};
</script>
