<template>
  <header class="w-full bg-white tracking-wide sticky top-0 z-20 text-nowrap">
    <div class="w-[20rem] p-5">
      <router-link to="/page1">
        <img
          src="../../assets/images/miningMongolia/header_logo.png"
          alt="logo"
          class="object-fit w-full"
        />
      </router-link>
    </div>

    <!-- for small screen -->
    <div class="min-[1200px]:hidden bg-green px-5 py-2.5">
      <button @click="toggleDrawer()" class="border border-white px-2 py-1">
        <font-awesome-icon icon="fa-solid fa-bars" class="text-xl text-white" />
      </button>
    </div>
    <transition name="fade" class="min-[1200px]:hidden">
      <div
        v-if="isDrawerOpen"
        class="fixed top-0 left-0 z-20 h-screen w-[18rem] bg-green text-white text-sm p-5"
      >
        <button
          @click="toggleDrawer(), selectCat('')"
          class="absolute top-3 right-3"
        >
          <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
        </button>

        <ul class="mt-10">
          <li
            @click="selectCat('A')"
            class="flex items-center justify-between w-full border-b border-slate-200 py-3"
          >
            Ассоциацийн тухай
            <font-awesome-icon
              v-if="isSelectedCat === 'A'"
              icon="fa-solid fa-add"
              class="text-sm"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-angle-down"
              class="text-sm"
            />
          </li>
          <div v-if="isSelectedCat === 'A'" class="ml-3">
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Бидний тухай" : "About us" }}
            </li>
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Бүтэц зохион байгуулалт" : "Organization" }}
            </li>
          </div>

          <button
            @click="selectCat('B')"
            class="flex items-center justify-between w-full border-b border-slate-200 py-3"
          >
            Үйл ажиллагаа<font-awesome-icon
              v-if="isSelectedCat === 'B'"
              icon="fa-solid fa-add"
              class="text-sm"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-angle-down"
              class="text-sm"
            />
          </button>
          <div v-if="isSelectedCat === 'B'" class="ml-3">
            <li class="p-2 hover:bg-lightGreen">
              {{
                lang === "mn" ? "Үндсэн ажлын хэсгүүд" : "Main working groups"
              }}
            </li>
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Төсөл хөтөлбөр" : "Projects" }}
            </li>
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Хууль эрх зүй" : "Legal" }}
            </li>
          </div>

          <li class="border-b border-slate-200 py-3">Мэдээ, мэдээлэл</li>
          <button
            @click="selectCat('C')"
            class="flex items-center justify-between w-full border-b border-slate-200 py-3"
          >
            Гишүүнчлэл<font-awesome-icon
              v-if="isSelectedCat === 'C'"
              icon="fa-solid fa-add"
              class="text-sm"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-angle-down"
              class="text-sm"
            />
          </button>
          <div v-if="isSelectedCat === 'C'" class="ml-3">
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Зөвлөлийн гишүүд" : "Our members" }}
            </li>
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Үндсэн гишүүд" : "Our partners" }}
            </li>
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Гишүүнээр элсэх" : "Become a member" }}
            </li>
          </div>

          <button
            @click="selectCat('D')"
            class="flex items-center justify-between w-full border-b border-slate-200 py-3"
          >
            Арга хэмжээ<font-awesome-icon
              v-if="isSelectedCat === 'D'"
              icon="fa-solid fa-add"
              class="text-sm"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-angle-down"
              class="text-sm"
            />
          </button>
          <div v-if="isSelectedCat === 'D'" class="ml-3 text-pretty">
            <li class="p-2 hover:bg-lightGreen">PDAC</li>
            <li class="p-2 hover:bg-lightGreen">IMARC</li>
            <li class="p-2 hover:bg-lightGreen">Mining week</li>
            <li class="p-2 hover:bg-lightGreen">
              {{ lang === "mn" ? "Бүсийн чуулган" : "RM Regional" }}
            </li>
            <li class="p-2 hover:bg-lightGreen">
              {{
                lang === "mn"
                  ? "Аж үйлдвэржилтийн үндэсний зөвлөгөөн"
                  : "National Industrialization Conference"
              }}
            </li>
          </div>

          <li class="border-b border-slate-200 py-3">Холбоо барих</li>
        </ul>
      </div>
    </transition>

    <!-- for large screen -->
    <nav class="hidden min-[1200px]:flex justify-center bg-green">
      <ul class="flex max-w-[1200px] w-full uppercase text-white text-sm">
        <li class="group relative w-full p-5 text-center hover:bg-lightGreen">
          {{ lang === "mn" ? "Ассоциацийн тухай" : "About us"
          }}<font-awesome-icon
            icon="fa-solid fa-angle-down"
            class="text-xs ml-1"
          />
          <ul
            class="invisible group-hover:visible absolute top-14 left-0 bg-green w-full text-pretty"
          >
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              {{ lang === "mn" ? "Бидний тухай" : "About us" }}
            </li>
            <li class="p-3 text-center hover:bg-lightGreen">
              {{ lang === "mn" ? "Бүтэц зохион байгуулалт" : "Organization" }}
            </li>
          </ul>
        </li>
        <li class="group relative w-full p-5 text-center hover:bg-lightGreen">
          {{ lang === "mn" ? "Үйл ажиллагаа" : "Activity"
          }}<font-awesome-icon
            icon="fa-solid fa-angle-down"
            class="text-xs ml-1"
          />
          <ul
            class="invisible group-hover:visible absolute top-14 left-0 bg-green w-full text-pretty"
          >
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              {{
                lang === "mn" ? "Үндсэн ажлын хэсгүүд" : "Main working groups"
              }}
            </li>
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              {{ lang === "mn" ? "Төсөл хөтөлбөр" : "Projects" }}
            </li>
            <li class="p-3 text-center hover:bg-lightGreen">
              {{ lang === "mn" ? "Хууль эрх зүй" : "Legal" }}
            </li>
          </ul>
        </li>
        <li class="w-full p-5 text-center hover:bg-lightGreen">
          {{ lang === "mn" ? "Мэдээ, мэдээлэл" : "News & Stories" }}
        </li>
        <li class="group relative w-full p-5 text-center hover:bg-lightGreen">
          {{ lang === "mn" ? "Гишүүнчлэл" : "Membership"
          }}<font-awesome-icon
            icon="fa-solid fa-angle-down"
            class="text-xs ml-1"
          />
          <ul
            class="invisible group-hover:visible absolute top-14 left-0 bg-green w-full text-pretty"
          >
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              {{ lang === "mn" ? "Зөвлөлийн гишүүд" : "Our members" }}
            </li>
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              {{ lang === "mn" ? "Үндсэн гишүүд" : "Our partners" }}
            </li>
            <li class="p-3 text-center hover:bg-lightGreen">
              {{ lang === "mn" ? "Гишүүнээр элсэх" : "Become a member" }}
            </li>
          </ul>
        </li>
        <li class="group relative w-full p-5 text-center hover:bg-lightGreen">
          {{ lang === "mn" ? "Арга хэмжээ" : "Events"
          }}<font-awesome-icon
            icon="fa-solid fa-angle-down"
            class="text-xs ml-1"
          />
          <ul
            class="invisible group-hover:visible absolute top-14 left-0 bg-green w-full text-pretty"
          >
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              PDAC
            </li>
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              IMARC
            </li>
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              Mining week
            </li>
            <li
              class="p-3 text-center border-b border-gray-200 hover:bg-lightGreen"
            >
              {{ lang === "mn" ? "Бүсийн чуулган" : "RM Regional" }}
            </li>
            <li class="p-3 text-center hover:bg-lightGreen">
              {{
                lang === "mn"
                  ? "Аж үйлдвэржилтийн үндэсний зөвлөгөөн"
                  : "National Industrialization Conference"
              }}
            </li>
          </ul>
        </li>
        <li class="w-full p-5 text-center hover:bg-lightGreen">
          {{ lang === "mn" ? "Холбоо барих" : "Contact us" }}
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  name: "MMNavbar",
  setup() {
    const store = useStore();
    const lang = ref();

    watchEffect(() => {
      lang.value = store.getters.getLanguage;
    });

    const isDrawerOpen = ref(false);
    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    const isSelectedCat = ref("");
    const selectCat = (value) => {
      isSelectedCat.value = value;
    };

    return {
      lang,
      isDrawerOpen,
      toggleDrawer,
      isSelectedCat,
      selectCat,
    };
  },
};
</script>
