<template>
  <footer
    class="flex justify-center items-center bg-green text-white tracking-wide leading-5"
    :class="lang === 'mn' ? 'text-sm' : 'text-xs'"
  >
    <div
      v-motion-slide-visible-once-left
      :duration="1000"
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 max-[1210px]:p-5 max-w-[1200px] w-full py-5"
    >
      <!-- 1st column -->
      <div class="w-full md:w-[15rem]">
        <p class="mb-4">
          {{
            lang === "mn"
              ? "Монгол Улс, Улаанбаатар хот, СБД, 1-р хороо, Элчингийн гудамж, Скай Плаза бизнес төв, Д корпус 2 давхар"
              : "2nd floor, D section, “Sky Plaza” Business Center, Embassy Rd, Sukhbaatar district, Ulaanbaatar, Mongolia 14220"
          }}
        </p>
        <p class="mb-4">+976 70110163</p>
        <p class="mb-14">info@miningmongolia.com</p>
        <div class="flex items-center gap-5 text-lg">
          <a href="https://youtube.com" target="_blank">
            <font-awesome-icon icon="fa-brands fa-youtube" />
          </a>
          <a href="https://linkedin.com" target="_blank">
            <font-awesome-icon icon="fa-brands fa-linkedin-in" />
          </a>
          <a href="https://twitter.com" target="_blank">
            <font-awesome-icon icon="fa-brands fa-x-twitter" />
          </a>
          <a href="https://facebook.com" target="_blank">
            <font-awesome-icon icon="fa-brands fa-facebook-f" />
          </a>
          <a href="https://instagram.com" target="_blank">
            <font-awesome-icon icon="fa-brands fa-instagram" />
          </a>
        </div>
      </div>

      <!-- 2nd column -->
      <div class="space-y-5">
        <ul>
          <li class="font-bold mb-1">
            {{ lang === "mn" ? "Ассоциацийн тухай" : "About us" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Бид хэн бэ?" : "Who are we?" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Бидний зорилт" : "Our focus" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Үйл ажиллагааны чиглэл" : "Our activity" }}
          </li>
        </ul>
        <ul>
          <li class="font-bold mb-1">
            {{ lang === "mn" ? "Бүтэц зохион байгуулалт" : "Organization" }}
          </li>
          <li class="hover:underline">
            {{
              lang === "mn" ? "Ассоциацийн зөвлөл" : "Board of the Assocation"
            }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Ажлын алба" : "MNMA staff" }}
          </li>
        </ul>
      </div>

      <!-- 3rd column -->
      <div class="space-y-5">
        <ul>
          <li class="font-bold mb-1">
            {{ lang === "mn" ? "Гишүүнчлэл" : "Membership" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Зөвлөлийн гишүүн" : "Our members" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Үндсэн гишүүн" : "Our partners" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Гишүүнээр элсэх" : "Become a member" }}
          </li>
        </ul>
        <ul>
          <li class="font-bold mb-1">
            {{
              lang === "mn"
                ? "Хариуцлагатай Уул Уурхай"
                : "Responsible Mining Program"
            }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "ХУУ сайн дурын кодекс" : "RM CODEX" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "ХУУ сургалт" : "RM Training" }}
          </li>
          <li class="hover:underline">
            {{ lang === "mn" ? "XУУ өөрийн үнэлгээ" : "RM Self-assessment" }}
          </li>
        </ul>
      </div>

      <!-- 4th column -->
      <div>
        <ul>
          <li class="font-bold mb-1">
            {{ lang === "mn" ? "Арга хэмжээ" : "Events" }}
          </li>
          <li class="hover:underline">PDAC</li>
          <li class="hover:underline">IMARC</li>
          <li class="hover:underline">MINING WEEK</li>
          <li class="hover:underline">
            {{ lang === "mn" ? "Бүсийн чуулган" : "RM Regional" }}
          </li>
          <li class="hover:underline">
            {{
              lang === "mn"
                ? "Аж үйлдвэржилтийн үндэсний зөвлөгөөн"
                : "National Industrialization Conference"
            }}
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  name: "MMFooter",
  setup() {
    const store = useStore();
    const lang = ref();

    watchEffect(() => {
      lang.value = store.getters.getLanguage;
    });

    return {
      lang,
    };
  },
};
</script>
