<template>
  <MMContactNavbar />
  <MMNavbar />
  <main class="tracking-wide">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }"
      :pagination="{ clickable: true }"
      class="mySwiper"
    >
      <swiper-slide v-for="(slide, index) in header_slide" :key="index">
        <img :src="slide" alt="image" class="w-full object-fit" />
      </swiper-slide>
    </swiper>

    <!-- NEWS -->
    <section class="flex justify-center my-10">
      <div
        class="flex max-md:flex-col max-w-[1200px] w-full gap-5 lg:gap-10 max-[1210px]:p-5"
      >
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :navigation="true"
          :loop="true"
          class="customSwiper hidden md:block"
        >
          <swiper-slide v-for="(slide, index) in resource_slide" :key="index">
            <img :src="slide" alt="image" class="w-full object-fit" />
          </swiper-slide>
        </swiper>
        <div class="border-2 border-gray-200 p-5">
          <div class="flex items-center">
            <hr class="border-2 border-green w-[1.5rem] mr-1" />
            <hr class="border-2 border-green w-[0.3rem] mr-3" />
            <h1 class="uppercase text-lg">
              {{ lang === "mn" ? "Шинэ мэдээ" : "Recent news" }}
            </h1>
          </div>
          <ul>
            <li
              v-for="(news, index) in news"
              :key="index"
              class="flex gap-3 py-3"
            >
              <img
                :src="news.image"
                :alt="news.title"
                class="w-[7rem] sm:w-[10rem] h-[8rem] object-cover object-center"
              />
              <div>
                <a :href="news.link" class="line-clamp-4 hover:underline">{{
                  news.title
                }}</a>
                <div class="flex gap-2 text-[#69a1bb] text-xs mt-2">
                  <font-awesome-icon icon="fa-solid fa-calendar-days" />
                  {{ news.published }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- SERVICES -->
    <section class="flex justify-center items-center my-20">
      <div
        class="max-w-[1200px] w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 max-[1210px]:p-5"
      >
        <div
          v-for="(page, index) in pages"
          :key="index"
          v-motion-slide-visible-once-left
          :duration="1000"
          class="rounded px-5 py-14 shadow-[0px_10px_29.7px_0.3px_rgba(225,225,225,0.64)]"
        >
          <img
            :src="page.image"
            :alt="index"
            class="w-[7rem] h-[3rem] object-fit"
          />
          <span
            class="line-clamp-3 font-light my-5"
            :class="
              lang === 'mn'
                ? 'tracking-wider'
                : 'tracking-normal text-sm leading-6'
            "
            >{{ lang === "mn" ? page.text : page.text_en }}</span
          >
          <button
            class="text-white py-2 px-6 rounded-full hover:before:bg-lightGreen relative overflow-hidden bg-green transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-lightGreen before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full"
          >
            <span class="relative z-10">
              {{ lang === "mn" ? "Дэлгэрэнгүй" : "Read more"
              }}<font-awesome-icon
                icon="fa-solid fa-arrow-right-long"
                class="ml-2 text-xs"
            /></span>
          </button>
        </div>
      </div>
    </section>
  </main>

  <MMFooter />
  <QuickContact :backgroundColor="'bg-green'" />
  <BackToTheTopButton :backgroundColor="'bg-green'" />
</template>

<script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/bundle";

import MMContactNavbar from "@/components/MiningMongolia/MMContactNavbar.vue";
import MMNavbar from "../../components/MiningMongolia/MMNavbar";
import MMFooter from "../../components/MiningMongolia/MMFooter";
import BackToTheTopButton from "@/components/ui/BackToTheTopButton.vue";
import QuickContact from "@/components/ui/QuickContact.vue";

export default {
  name: "MMHomePage",
  components: {
    MMContactNavbar,
    MMNavbar,
    MMFooter,
    BackToTheTopButton,
    QuickContact,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const news = [
      {
        title:
          "ОҮИТБС-ын зүгээс цахим тайлагналын системийг шинэчлэн нэвтрүүлж байна.",
        published: "27 Feb, 2024",
        link: "https://www.miningmongolia.mn/blog/15",
        image: require("../../assets/images/miningMongolia/news/news-1.jpeg"),
      },
      {
        title:
          "УУЛ УУРХАЙН САЛБАРААС УЛС ТӨРИЙН НАМ, ХҮЧНҮҮДЭД ХАНДАН УРИАЛГА ГАРГАЛАА",
        published: "23 Feb, 2024",
        link: "https://www.miningmongolia.mn/blog/14",
        image: require("../../assets/images/miningMongolia/news/news-2.jpeg"),
      },
      {
        title: "Miners Day",
        published: "08 Feb, 2024",
        link: "https://www.miningmongolia.mn/blog/13",
        image: require("../../assets/images/miningMongolia/news/news-3.jpeg"),
      },
    ];

    const pages = [
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/page-1.png"),
        text: "Уурхайг ашигласнаар улс орны хөгжилд нь нэмэртэй, ард түмний амьдралд тустай, байгаль орчин, хүний эрүүл мэндэд хохиролгүй байхыг хэлэх ба энэ нь уул уурхайг төлөвлөж эхлэхээс авахуулаад дуусах хүртэл хийгдэх олон талын цогц арга хэмжээ юм. ",
        text_en:
          "The utilization of mines should contribute to the country's development, enrich people's lives, while ensuring the preservation of the environment and human health. The Mongolian National Mining Association has launched a responsible mining initiative in Mongolia since 2006 to `actively participate in the development of responsible mountains in Mongolia's mineral industry` and working to implement this principle.",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/page-2.png"),
        text: "Монголын уул уурхайн компаниудын нийгмийн хариуцлагаа дээшлүүлэх, тэдгээрийн тогтвортой хөгжлийг хангах зорилгоор",
        text_en:
          "The utilization of mines should contribute to the country's development, enrich people's lives, while ensuring the preservation of the environment and human health. The Mongolian National Mining Association has launched a responsible mining initiative in Mongolia since 2006 to `actively participate in the development of responsible mountains in Mongolia's mineral industry` and working to implement this principle.",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/page-3.png"),
        text: "Монголын уул уурхайн компаниудын нийгмийн хариуцлагаа дээшлүүлэх, тэдгээрийн тогтвортой хөгжлийг хангах зорилгоор",
        text_en:
          "The utilization of mines should contribute to the country's development, enrich people's lives, while ensuring the preservation of the environment and human health. The Mongolian National Mining Association has launched a responsible mining initiative in Mongolia since 2006 to `actively participate in the development of responsible mountains in Mongolia's mineral industry` and working to implement this principle.",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/page-4.png"),
        text: "“Монгол улсын эрдэс баялгийн салбарын боловсон хүчний хэрэгцээ шаардлага болон боломжит олон улсын тэтгэлгийн танилцуулга” цуврал уулзалт.",
        text_en:
          "`Introduction of the personnel needs of Mongolia`s mineral resources sector and possible international scholarships` series of meetings",
      },
      {
        image: require("../../assets/images/miningMongolia/home-pages-cards/page-5.png"),
        text: "",
        text_en:
          "Members of the Mongolian National Mining Association are highlighted every month",
      },
    ];

    const header_slide = [
      require("../../assets/images/miningMongolia/home-header/header-1.png"),
      require("../../assets/images/miningMongolia/home-header/header-2.png"),
      require("../../assets/images/miningMongolia/home-header/header-3.png"),
    ];

    const resource_slide = [
      require("../../assets/images/miningMongolia/home-resource-slide/resource-1.png"),
      require("../../assets/images/miningMongolia/home-resource-slide/resource-2.png"),
      require("../../assets/images/miningMongolia/home-resource-slide/resource-3.png"),
    ];

    const store = useStore();
    const lang = ref();

    watchEffect(() => {
      lang.value = store.getters.getLanguage;
    });

    return {
      news,
      pages,
      lang,
      header_slide,
      resource_slide,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>

<style scoped>
.mySwiper {
  --swiper-pagination-color: #537d23;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  --swiper-button-prev: #537d23;
}

.customSwiper {
  position: relative;
}

.customSwiper :deep(.swiper-button-next),
.customSwiper :deep(.swiper-button-prev) {
  background-color: white;
  opacity: 50%;
  color: #537d23;
  width: 40px;
  height: 40px;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.customSwiper:hover :deep(.swiper-button-next),
.customSwiper:hover :deep(.swiper-button-prev) {
  display: flex;
}

.customSwiper :deep(.swiper-button-next::after),
.customSwiper :deep(.swiper-button-prev::after) {
  font-size: 10px;
}

.customSwiper :deep(.swiper-button-next:hover),
.customSwiper :deep(.swiper-button-prev:hover) {
  background-color: #537d23;
  color: white;
  opacity: 100%;
}
</style>
