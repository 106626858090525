<template>
  <header
    class="flex max-sm:flex-col sm:justify-between max-sm:gap-3 items-center text-green px-5 pt-5"
  >
    <button @click="toggleLanguage()">
      <font-awesome-icon icon="fa-solid fa-globe" class="mr-1" />{{
        lang === "mn" ? "Монгол" : "English"
      }}
    </button>
    <div class="flex items-center gap-5">
      <a href="https://youtube.com" target="_blank">
        <font-awesome-icon icon="fa-brands fa-youtube" />
      </a>
      <a href="https://linkedin.com" target="_blank">
        <font-awesome-icon icon="fa-brands fa-linkedin-in" />
      </a>
      <a href="https://twitter.com" target="_blank">
        <font-awesome-icon icon="fa-brands fa-x-twitter" />
      </a>
      <a href="https://facebook.com" target="_blank">
        <font-awesome-icon icon="fa-brands fa-facebook-f" />
      </a>
      <a href="https://instagram.com" target="_blank">
        <font-awesome-icon icon="fa-brands fa-instagram" />
      </a>
    </div>
  </header>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  name: "MMContactNavbar",
  setup() {
    const store = useStore();
    const lang = ref("");

    watchEffect(() => {
      lang.value = store.getters.getLanguage;
    });

    const toggleLanguage = () => {
      store.dispatch("toggleLanguage");
    };

    return {
      lang,
      toggleLanguage,
    };
  },
};
</script>
