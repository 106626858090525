export default {
  state() {
    return {
      language: localStorage.getItem("language") || "mn",
    };
  },

  getters: {
    getLanguage(state) {
      return state.language;
    },
  },

  mutations: {
    setLanguage(state, payload) {
      state.language = payload;
      localStorage.setItem("language", payload);
    },
  },

  actions: {
    toggleLanguage({ state, commit }, payload) {
      console.log(payload);
      const newLanguage = payload || (state.language === "mn" ? "en" : "mn");
      commit("setLanguage", newLanguage);
    },
  },
};
